<template>
	<div class="animated fadeIn">
		<b-card class="card-border mt-4">
			<b-card-title><i class="fa fa-pie-chart"></i> Asset Pool Distribution</b-card-title>
			<b-card-sub-title>Handles the asset pool distribution based on company connections within the supply chain</b-card-sub-title>

			<div fluid class="px-2 mt-4">
				<PrimaryDistributions v-if="isSuperAdmin || !hasAssetPoolingPermission" />
				<b-tabs v-else>
					<b-tab title="Primary" active>
						<PrimaryDistributions />
					</b-tab>
					<b-tab title="Others">
						<OtherDistributions />
					</b-tab>
				</b-tabs>
			</div>
		</b-card>
	</div>
</template>

<script>
// Components
import PrimaryDistributions from '@/views/transactions/assetPoolDistribution/PrimaryDistributions';
import OtherDistributions from '@/views/transactions/assetPoolDistribution/OtherDistributions';


export default {
	name: 'asset-pool-distribution',
	components: {
		PrimaryDistributions,
		OtherDistributions
	},
	data() {
		return {
			isSuperAdmin: this.$store.getters.isSuperAdmin,
			hasAssetPoolingPermission: this.$store.getters.hasAssetPoolingPermission,
		};
	}
};
</script>

<style scoped>
</style>