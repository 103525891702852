import { db } from '@/config/firebase';
import _ from 'lodash';


async function fetchDispatchesByPlateNo(plateNo) {
    let dispatchesObj = {};

    const dbRef = db.collection('dispatches').where("transportation.plateNo", "==", plateNo);
    const querySnapshots = await dbRef.get();

    querySnapshots.forEach(doc => {
        const id = doc.id;
        let dispatchObj = doc.data();
        dispatchesObj[id] = {
            id: id,
            ...dispatchObj
        }
    });

    return dispatchesObj;
}

function generateQuery(filterBy = {}) {
    let query = db.collection("dispatches");

    // Date Created
    if (filterBy.fromTimestamp && filterBy.fromTimestamp > 0) {
        query = query.where("dateCreated", ">=", filterBy.fromTimestamp);
    }
    if (filterBy.toTimestamp && filterBy.toTimestamp > 0) {
        query = query.where("dateCreated", "<=", filterBy.toTimestamp);
    }

    // Date Received
    if (filterBy.receivedStartDate && filterBy.receivedStartDate > 0) {
        query = query.where("dateReceived", ">=", filterBy.receivedStartDate);
    }
    if (filterBy.receivedEndDate && filterBy.receivedEndDate > 0) {
        query = query.where("dateReceived", "<=", filterBy.receivedEndDate);
    }

    // Is Delayed Transfer?
    if (Object.prototype.hasOwnProperty.call(filterBy, 'isDelayedTransfer')) {
        query = query.where("isDelayedTransfer", "==", filterBy.isDelayedTransfer);
    }

    // Delayed Transfer Maturity Date
    if (filterBy.maturityStartDate && filterBy.maturityStartDate > 0) {
        query = query.where("delayedTransferMaturityDate", ">=", filterBy.maturityStartDate);
    }
    if (filterBy.maturityEndDate && filterBy.maturityEndDate > 0) {
        query = query.where("delayedTransferMaturityDate", "<=", filterBy.maturityEndDate);
    }

    // Source
    if (filterBy.sourceCompany && filterBy.sourceCompany.id && filterBy.sourceCompany.id.length > 0) {
        query = query.where("source.companyId", "==", filterBy.sourceCompany.id);
    } else if (filterBy.sourceCompany && filterBy.sourceCompany.ids && !_.isEmpty(filterBy.sourceCompany.ids)) {
        query = query.where("source.companyId", "in", filterBy.sourceCompany.ids);
    }

    // Destination
    if (filterBy.destinationCompany && filterBy.destinationCompany.id && filterBy.destinationCompany.id.length > 0) {
        query = query.where("destination.companyId", "==", filterBy.destinationCompany.id);
    } else if (filterBy.destinationCompany && filterBy.destinationCompany.ids && !_.isEmpty(filterBy.destinationCompany.ids)) {
        query = query.where("destination.companyId", "in", filterBy.destinationCompany.ids);
    }

    // Client Account No
    if (filterBy.accountNo && filterBy.accountNo.id && filterBy.accountNo.id.length > 0) {
        query = query.where("clientAccountId", "==", filterBy.accountNo.id);
    } else if (filterBy.accountNo && filterBy.accountNo.ids && !_.isEmpty(filterBy.accountNo.ids)) {
        query = query.where("clientAccountId", "in", filterBy.accountNo.ids);
    }

    // Plate No. / Conduction No.
    if (filterBy.plateNo && filterBy.plateNo.length > 0) {
        query = query.where("transportation.plateNo", "==", filterBy.plateNo);
    }

    // Driver
    if (filterBy.driver && filterBy.driver.id && filterBy.driver.id.length > 0) {
        query = query.where("driver.userId", "==", filterBy.driver.id);
    }

    // Status
    if (filterBy.status && filterBy.status.length > 0) {
        query = query.where("status", "==", filterBy.status);
    }

    // Truck Company
    if (filterBy.truckCompany && filterBy.truckCompany.id && filterBy.truckCompany.id.length > 0) {
        query = query.where("transportation.companyId", "==", filterBy.truckCompany.id);
    }

    // Dispatch Id
    if (filterBy.dispatchId && filterBy.dispatchId.length > 0) {
        query = query.where("dispatchId", "==", filterBy.dispatchId);
    }

    // Dispatch No
    if (filterBy.dispatchNo && filterBy.dispatchNo.length > 0) {
        query = query.where("dispatchNo", "==", filterBy.dispatchNo);
    }

    return query;
}

function getDispatchListener(filter, callback) {
    let query = generateQuery(filter);
    let listener = query.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
            callback(change.type, change.doc.data());
        });
    });
    return listener;
}

async function getDispatches(filter) {
    let dispatchesObj = {};

    let query = generateQuery(filter);
    const querySnapshots = await query.get();

    querySnapshots.forEach(doc => {
        const id = doc.id;
        let dispatchObj = doc.data();
        dispatchesObj[id] = {
            id: id,
            ...dispatchObj
        }
    });

    return dispatchesObj;
}

async function getInTransitDispatchesFromSource(companyId, storageLocationId) {
    let dispatchesObj = {};

    let query = db.collection('dispatches')
        .where('status', '==', 'In-Transit');
    if (companyId) {
        query = query.where('source.companyId', '==', companyId);
    }
    if (storageLocationId) {
        query = query.where('source.storageLocationId', '==', storageLocationId);
    }

    const querySnapshot = await query.get();
    querySnapshot.forEach(doc => {
        let dispatchObj = doc.data();
        dispatchObj.id = doc.id;
        dispatchesObj[doc.id] = dispatchObj;
    });
    return dispatchesObj;
}

async function getDispatchByDispatchId(dispatchId) {
    let query = db.collection('dispatches')
        .where('dispatchId', '==', dispatchId)
    const querySnapshot = await query.get();

    let dispatchObj = {};
    querySnapshot.forEach(item => {
        dispatchObj = item.data();
        dispatchObj.id = item.id;
    });

    return dispatchObj;
}

export default {
    fetchDispatchesByPlateNo,
    getDispatchListener,
    getDispatches,
    getInTransitDispatchesFromSource,
    getDispatchByDispatchId
}