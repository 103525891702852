import axios from 'axios';
import config from '@/config/env-constants';

function getProjectId() {
    if (config.currEnv === 'pallet-pooling') {
        return `${config.currEnv}`;
    } else {
        return `ayun-quantity-${config.currEnv}`;
    }
}

export default {
    baseUrl: `https://us-central1-${getProjectId()}.cloudfunctions.net`,

    paginateAssetPoolDistributions(page, limit, startAt, direction, filters, currUserId) {
        let url = `${this.baseUrl}/paginateAssetPoolDistributions`;
        return axios.post(url, { ...filters, page, limit, startAt, direction, currUserId });
    },

    getAssetPoolDistributions(filterBy, view, currUserId) {
        const url = `${this.baseUrl}/getAssetPoolDistributions`;
        return axios.post(url, {
            view: view,
            currUserId: currUserId,
            filterBy: JSON.stringify(filterBy)
        });
    },

    saveAssetPoolDistribution(param) {
        let url = `${this.baseUrl}/saveAssetPoolDistribution`;
        return axios.post(url, {
            currUserId: param.currUserId,
            currTimeStamp: param.currTimeStamp,
            assetPoolDistribution: JSON.stringify(param.assetPoolDistribution)
        });
    },
}