<template>
	<div class="animated fadeIn">
        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
        <div v-if="!isSuperAdmin && hasAssetPoolingPermission">
            <div class="details-view-title">PRIMARY DISTRIBUTIONS</div>
            <div class="details-view-subtitle">Distribution from your current company and subsidiaries </div>
        </div>

		<!-- Filter  -->
        <b-row class="mt-4">
            <b-col sm="12" md="2" lg="3">
                <b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
                    class="filter">
                    FILTER OPTIONS
                </b-button>
            </b-col>
            <b-col sm="12">
                <!-- Collapsible Filter Options -->
                <b-collapse id="collapse-1" class="mt-2">
                    <b-card>
                        <b-row no-gutters>
                            <b-col lg="4" md="6" sm="12" class="mr-4">
                                <b-form-group label="Company">
                                    <v-select class="style-chooser" label="text" placeholder=" - Please select - "
                                        :options="filterByOptions.companyItems"
                                        :reduce="(company) => company.value" v-model="filterBy.company">
                                        <template v-slot:no-options="{ search, searching }">
                                            <template v-if="searching">
                                                No results found for
                                                <em>
                                                    <strong>{{ search }}</strong>
                                                </em>
                                            </template>
                                            <em :style="{ opacity: 0.5 }" v-else>
                                                Start typing to search for a company
                                            </em>
                                        </template>
                                    </v-select>
                                </b-form-group>
                            </b-col>
                            <b-col lg="4" md="6" sm="12" class="mr-4">
                                <b-form-group label="Asset Type">
                                    <v-select class="style-chooser" label="text" placeholder=" - Please select - "
                                        :options="filterByOptions.assetTypeItems"
                                        :reduce="(assetType) => assetType.value" v-model="filterBy.assetType">
                                        <template v-slot:no-options="{ search, searching }">
                                            <template v-if="searching">
                                                No results found for
                                                <em>
                                                    <strong>{{ search }}</strong>
                                                </em>
                                            </template>
                                            <em :style="{ opacity: 0.5 }" v-else>
                                                Start typing to search for a asset type
                                            </em>
                                        </template>
                                    </v-select>
                                </b-form-group>
                            </b-col>
                            <b-col lg="4" md="6" sm="12" class="mr-4">
                                <b-form-group label="Connection" description="Company">
                                    <v-select class="style-chooser" label="text" placeholder=" - Please select - "
                                        :options="filterByOptions.connectedCompanyItems"
                                        :reduce="(company) => company.value"
                                        v-model="filterBy.connectedCompany">
                                        <template v-slot:no-options="{ search, searching }">
                                            <template v-if="searching">
                                                No results found for
                                                <em>
                                                    <strong>{{ search }}</strong>
                                                </em>
                                            </template>
                                            <em :style="{ opacity: 0.5 }" v-else>
                                                Start typing to search for a company
                                            </em>
                                        </template>
                                    </v-select>
                                </b-form-group>
                            </b-col>
                            <b-col lg="4" md="6" sm="12" class="mr-4">
                                <b-form-group description="Storage Location" class="field-without-label">
                                    <v-select class="style-chooser" label="text" placeholder=" - Please select - "
                                        :options="filterByOptions.connectedStorageLocationItems"
                                        :reduce="(loc) => loc.value"
                                        v-model="filterBy.connectedStorageLocation">
                                        <template v-slot:no-options="{ search, searching }">
                                            <template v-if="searching">
                                                No results found for
                                                <em>
                                                    <strong>{{ search }}</strong>
                                                </em>
                                            </template>
                                            <em :style="{ opacity: 0.5 }" v-else>
                                                >Start typing to search for a storage location
                                            </em>
                                        </template>
                                    </v-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row no-gutters>
                            <b-col lg="4" md="6" sm="12" class="mr-4">
                                <b-form-group label="Distribution Type">
                                    <v-select class="style-chooser" label="text" placeholder=" - Please select - "
                                        :options="filterByOptions.typeItems"
                                        :reduce="(type) => type.value" v-model="filterBy.type">
                                        <template v-slot:no-options="{ search, searching }">
                                            <template v-if="searching">
                                                No results found for
                                                <em>
                                                    <strong>{{ search }}</strong>
                                                </em>
                                            </template>
                                            <em :style="{ opacity: 0.5 }" v-else>
                                                Start typing to search for a distribution type
                                            </em>
                                        </template>
                                    </v-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row no-gutters>
                            <b-col sm="12">
                                <b-button class="mr-1" variant="success" @click="onFilterRequest">
                                    Search
                                </b-button>
                                <b-button class="mr-1" variant="primary" @click="resetFilters">
                                    Reset
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-collapse>
            </b-col>
        </b-row>

        <!-- Select Actions and Items Per Page Options -->
        <b-row>
            <b-col sm="6" md="3" class="mt-4 mb-2">
                <b-dropdown id="distribution-select-actions" text=" Select Actions " variant="dark" slot="append">
                    <b-dropdown-item v-b-modal.add-asset-pool-distribution v-show="isAllowedToCreateDistribution()">
                        Add Asset Pool Distribution
                    </b-dropdown-item>
                    <b-dropdown-item>
                        <json-excel type="xls"
                            :name="fileName + '.xls'"
                            :fetch="fetchData"
                            :fields="exportFields"
                            :before-generate="startDownload"
                            :before-finish="finishDownload">
                            Export Asset Pool Distributions in Excel
                        </json-excel>
                    </b-dropdown-item>
                    <b-dropdown-item>
                        <json-excel type="csv"
                            :name="fileName + '.csv'"
                            :fetch="fetchData"
                            :fields="exportFields"
                            :before-generate="startDownload"
                            :before-finish="finishDownload">
                            Export Asset Pool Distributions to CSV
                        </json-excel>
                    </b-dropdown-item>
                </b-dropdown>
            </b-col>
            <b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
                <b-input-group prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="pagination.perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table ref="distributionsTable" show-empty striped hover :items="items" :fields="fields" responsive>
            <template v-slot:cell(company)="row">
                <span class="company-display">
                    {{ row.item.company }}
                </span>
            </template>
            <template v-slot:cell(connection)="row">
                <span class="company-display">
                    {{ row.item.connectedCompany }}
                </span><br/>
                <span class="location-display">
                    ({{ row.item.connectedStorageLocation }})
                </span>
            </template>
            <template v-slot:cell(type)="row">
                <span v-if="row.item.type === 'On-Hand'">
                    <b-badge variant="primary">{{ row.item.type }}</b-badge>
                </span>
                <span v-else-if="row.item.type === 'On-Hire'">
                    <b-badge variant="warning">{{ row.item.type }}</b-badge>
                </span>
                <span v-else>
                    <b-badge variant="danger">{{ row.item.type }}</b-badge>
                </span>
            </template>
            <template v-slot:cell(assetLimit)="row">
                <span class="numFont">
                    {{ getFormattedNumber(row.item.assetLimit) }}
                </span>
            </template>
            <template v-slot:cell(total)="row">
                <div v-if="isLimitExceeded(row.item)" class="numFont exceed-limit" v-b-tooltip.hover.top="getLimitToolTip(row.item)">
                    <b-icon icon="exclamation-circle-fill"></b-icon> {{ getFormattedNumber(row.item.total) }}
                </div>
                <div v-else class="numFont within-limit">
                    {{ getFormattedNumber(row.item.total) }}
                </div>
            </template>
            <template v-slot:cell(totalInTransit)="row">
                <span class="numFont in-transit">
                    {{ getFormattedNumber(row.item.totalInTransit) }}
                </span>
            </template>
            <template v-slot:cell(totalEstimate)="row">
                <span class="numFont">
                    <strong>{{ getFormattedNumber(row.item.totalEstimate) }}</strong>
                </span>
            </template>

            <template v-slot:cell(actions)="row">
                <span class="text-nowrap">
                    <b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="dark"
                        @click.stop="row.toggleDetails" class="mr-1">
                        <i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
                        <i class="fa fa-eye" v-else></i>
                    </b-button>
                    <b-button v-show="isAllowedToAdjustTotal(row.item)" size="sm" v-b-modal.edit-asset-pool-distribution
                        v-b-tooltip.hover.top="'Edit Details'" variant="warning"
                        @click.stop="updateSelAssetPoolDistribution(row.item)" class="mr-1">
                        <i class="fa fa-pencil"></i>
                    </b-button>
                    <b-button v-show="isAllowedToUpdateLimit()" size="sm" v-b-modal.update-asset-limit
                        v-b-tooltip.hover.top="'Update Asset Limit'" variant="primary"
                        @click.stop="updateAssetLimit(row.item)" class="mr-1">
                        <i class="icon-speedometer"></i>
                    </b-button>
                </span>
            </template>

            <template slot="row-details" slot-scope="row">
                <AssetPoolDistributionDetailsView :row="row" />
            </template>
        </b-table>

        <b-row class="my-1">
            <b-col md="8" sm="12" >
            </b-col>
            <b-col md="4" sm="12">
                <b-pagination align="right" @change="onPageChanged" :total-rows="pagination.total"
                    :per-page="pagination.perPage" v-model="pagination.currentPage" class="my-0" limit="1" prev-text="Prev"
                    next-text="Next" hide-goto-end-buttons />
            </b-col>
        </b-row>

		<!-- Modals here -->
		<AddAssetPoolDistribution :companyOptions="filterByOptions.companyItems" 
			:allCompaniesObj="allCompaniesObj"
			:allConnectedCompaniesObj="allConnectedCompaniesObj"
			:allAssetTypesObj="allAssetTypesObj" 
			:allConnectionsObj="allConnectionsObj"
			:allStorageLocationsObj="allStorageLocationsObj"
			:allClientAccountsObj="allClientAccountsObj"
			:allAssetPoolDistributionsObj="allAssetPoolDistributionsObj" />
		<EditAssetPoolDistribution :allCompaniesObj="allCompaniesObj"
			:allConnectedCompaniesObj="allConnectedCompaniesObj"
			:allAssetTypesObj="allAssetTypesObj"  />
		<UpdateAssetLimit :allCompaniesObj="allCompaniesObj"
			:allConnectedCompaniesObj="allConnectedCompaniesObj"
			:allAssetTypesObj="allAssetTypesObj" />
	</div>
</template>

<script>
// Components
import AddAssetPoolDistribution from '@/views/transactions/assetPoolDistribution/AddAssetPoolDistribution';
import EditAssetPoolDistribution from '@/views/transactions/assetPoolDistribution/EditAssetPoolDistribution';
import AssetPoolDistributionDetailsView from '@/views/transactions/assetPoolDistribution/AssetPoolDistributionDetailsView';
import UpdateAssetLimit from '@/views/transactions/assetPoolDistribution/UpdateAssetLimit';

// Utils
import { AssetPoolDistributionUtil } from '@/utils/assetPoolDistributionUtil';
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';
import { UserUtil } from '@/utils/userutil';

// API & DAO
import assetPoolDistributionApi from '@/api/assetPoolDistributionApi';
import dispatchDAO from '@/database/dispatches';

// Others
import config from '@/config/env-constants';
import EventBus from '@/shared/event-bus';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import JsonExcel from 'vue-json-excel';
import _ from 'lodash';


export default {
	name: 'primary-distributions',
	components: {
		AddAssetPoolDistribution,
		EditAssetPoolDistribution,
		AssetPoolDistributionDetailsView,
		UpdateAssetLimit,
		Loading,
		JsonExcel,
	},
	data() {
		return {
			items: [],
			fields: [
				{
					key: 'company',
					sortable: false,
				},
				{
					key: 'connection',
					sortable: false,
				},
				{
					key: 'assetType',
					sortable: false,
				},
				{
					key: 'type',
					sortable: true,
					class: 'text-right',
				},
				{
					key: 'assetLimit',
					sortable: true,
					class: 'text-right',
				},
				{
					key: 'total',
					label: 'Total',
					sortable: true,
					class: 'text-right',
				},
				{
					key: 'totalInTransit',
					label: 'Total In-Transit',
					sortable: true,
					class: 'text-right',
				},
				{
					key: 'totalEstimate',
					label: 'Total Estimate',
					sortable: true,
					class: 'text-right',
				},
				{
					key: 'actions',
					thClass: 'text-center'
				}
			],
			pageOptions: [5, 10, 15, 25, 50, 100],
			pagination: {
				perPage: 5,
				currentPage: 1,
				total: 0,
				startAt: [],
				endAt: []
			},

			defaultFilterBy: {
				company: { ...config.companyDefaultValue },
				connectedCompany: { ...config.companyDefaultValue },
				connectedStorageLocation: { ...config.storageLocationDefaultValue },
				assetType: { ...config.assetTypeDefaultValue },
				type: null,
			},
			filterBy: {
				company: { ...config.companyDefaultValue },
				connectedCompany: { ...config.companyDefaultValue },
				connectedStorageLocation: { ...config.storageLocationDefaultValue },
				assetType: { ...config.assetTypeDefaultValue },
				type: null,
			},
			prevFilter: {},
			filterByOptions: {
				companyItems: [],
				connectedCompanyItems: [],
				connectedStorageLocationItems: [],
				assetTypeItems: [],
				typeItems: AssetPoolDistributionUtil.getDistributionTypeOptions()
			},

			allCompaniesObj: {},
			allConnectedCompaniesObj: {},
			allConnectionsObj: {},
			allStorageLocationsObj: {},
			allAssetTypesObj: {},
			allClientAccountsObj: {},

			allAssetPoolDistributionsObj: {},
			selDistribution: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			isViewer: this.$store.getters.isViewer,
			isScanner: this.$store.getters.isScanner,
			isRetailer: this.$store.getters.isRetailer,
            hasAssetPoolingPermission: this.$store.getters.hasAssetPoolingPermission,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,
		};
	},
	watch: {
		'pagination.perPage'(value) {
			this.resetPagination();
			this.retrieveData(1, value, this.pagination.startAt, { ...this.filterBy });
		},
		'pagination.currentPage'(newVal, oldVal) {
			if (newVal === 1) {
				this.resetPagination();
				this.retrieveData(1, this.pagination.perPage, this.pagination.startAt, { ...this.filterBy });
			} else {
				if (newVal > oldVal) {
					this.filterBy.direction = 'asc';
					this.retrieveData(newVal, this.pagination.perPage, this.pagination.endAt, { ...this.filterBy });
				} else {
					this.filterBy.direction = 'desc';
					this.retrieveData(newVal, this.pagination.perPage, this.pagination.startAt, { ...this.filterBy });
				}
			}
		},
		"filterBy.company": function (newVal) {
			if (newVal && newVal.id !== null) {
				let connectionsObj = _.filter(this.allConnectionsObj, o => {
					return o.companyId === newVal.id;
				});

				// add the connected companies
				let connectedCompanies = [];
				if (connectionsObj) {
					connectedCompanies = _.map(connectionsObj, 'connectedCompany');
				}

				// add the current company as well
				connectedCompanies.push(this.allCompaniesObj[newVal.id]);

				this.filterByOptions.connectedCompanyItems = DropDownItemsUtil.retrieveCompanies(connectedCompanies);

				// reset dependent filter options
				this.filterBy.connectedCompany = { ...config.companyDefaultValue };
				this.filterBy.connectedStorageLocation = { ...config.storageLocationDefaultValue };
			} else {
				this.filterByOptions.connectedCompanyItems = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
				this.filterByOptions.connectedStorageLocationItems = DropDownItemsUtil.retrieveStorageLocations(this.allStorageLocationsObj);
			}
		},
		"filterBy.connectedCompany": function (newVal) {
			if (newVal && newVal.id !== null) {
				let locsObj = _.filter(this.allStorageLocationsObj, o => {
					return o.companyId === newVal.id;
				});
				this.filterByOptions.connectedStorageLocationItems = DropDownItemsUtil.retrieveStorageLocations(locsObj);
			
				// reset dependent filter options
				this.filterBy.connectedStorageLocation = { ...config.storageLocationDefaultValue };
			} else {
				this.filterByOptions.connectedStorageLocationItems = DropDownItemsUtil.retrieveStorageLocations(this.allStorageLocationsObj);
			}
		},
	},
	computed: {
		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			return {
				Company: 'company',
				Connection: 'connection',
				'Asset Type': 'assetType',
				Type: 'type',
				'Asset Limit': 'assetLimit',
				'Total': 'total',
				'Total In-Transit': 'totalInTransit',
				'Total Estimate': 'totalEstimate',
				Notes: 'notes',
				'Date Created': 'Date Created',
				'Date Updated': 'Date Updated',
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return 'PrimaryDistributions-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
		},
	},
	mounted() {
		setTimeout(async () => {
			try {
				// show loading indicator
				this.isLoading = true;

				this.allCompaniesObj = { ...this.$store.getters.companies };
				this.allConnectedCompaniesObj = this.isSuperAdmin ? { ...this.allCompaniesObj } : { ...this.$store.getters.connectedCompanies };
				this.allStorageLocationsObj = { ...this.$store.getters.storageLocations, ...this.$store.getters.connectedStorageLocations };
				this.allConnectionsObj = { ...this.$store.getters.connections };
				this.allAssetTypesObj = { ...this.$store.getters.assetTypes };
				this.allClientAccountsObj = { ...this.$store.getters.clientAccounts };

				this.filterByOptions.companyItems = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);

				if (this.isSuperAdmin) {
					this.filterByOptions.connectedCompanyItems = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
				} else {
					let allCompaniesObj = { ...this.allCompaniesObj, ...this.allConnectedCompaniesObj };
					this.filterByOptions.connectedCompanyItems = DropDownItemsUtil.retrieveCompanies(allCompaniesObj);
				}

				this.filterByOptions.connectedStorageLocationItems = DropDownItemsUtil.retrieveStorageLocations(this.allStorageLocationsObj);
				this.filterByOptions.assetTypeItems = DropDownItemsUtil.retrieveAssetTypes(this.allAssetTypesObj, true);

				// reset filter to default
				this.filterBy = { ...this.defaultFilterBy };

				await this.retrieveData(1, this.pagination.perPage, this.pagination.startAt, { ...this.filterBy });
			} catch (error) {
				this.$toaster.error('Error loading data. Please reload the page again');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		}, config.timeout);

		// Event Listeners
		EventBus.$on('onCloseSaveAssetPoolDistribution', async (distributionObj) => {
			if (!_.isEmpty(distributionObj)) {
				await this.updateTable(distributionObj);
			}
		});
	},
	created() {
		// Set Default Source Company
		if (!this.isSuperAdmin) {
			this.defaultFilterBy.company = DropDownItemsUtil.getCompanyItem(this.loggedUserCompany);
		}
	},
	methods: {
		async fetchData(){
			try {
				let filter = { ...this.filterBy };
				filter.companyId = this.loggedUserCompany.id;
                filter.includePrimary = true;
                filter.includeOthers = false;

				let view = this.isSuperAdmin ? config.view.ADMIN : config.view.COMPANY;
				let { data } = await assetPoolDistributionApi.getAssetPoolDistributions(filter, view, this.loggedUser.id);

				let exportData = Object.values(data.assetPoolDistributions);
				exportData = await this.addFieldsForDisplay(exportData);

				return exportData;
			} catch(error) {
				this.$toaster.error('Error exporting data. Please try again later.');
			}
			return [];
		},
		startDownload(){
			// show loading indicator
			this.isLoading = true;
			this.$toaster.success('Exporting data...');
		},
		finishDownload(){
			// hide loading indicator
			this.isLoading = false;
		},
		async updateTable(distributionObj) {
			if (!_.isEmpty(distributionObj)) {
				this.allAssetPoolDistributionsObj[distributionObj.id] = distributionObj;
				await this.filterTableContent(this.allAssetPoolDistributionsObj);
			}
		},
		async filterTableContent(distributionsObj) {
			let filteredObj = { ...distributionsObj };

			_.forEach(distributionsObj, (distribution, id) => {
				let companyIdFilter = this.filterBy.company.id;
				if (companyIdFilter !== null && companyIdFilter !== distribution.companyId) {
					delete filteredObj[id];
				}

				let connectedCompanyIdFilter = this.filterBy.connectedCompany.id;
				if (connectedCompanyIdFilter !== null && connectedCompanyIdFilter !== distribution.connectedCompanyId) {
					delete filteredObj[id];
				}

				let connectedLocIdFilter = this.filterBy.connectedStorageLocation.id;
				if (connectedLocIdFilter !== null && connectedLocIdFilter !== distribution.connectedStorageLocationId) {
					delete filteredObj[id];
				}

				let assetTypeIdFilter = this.filterBy.assetType.id;
				if (assetTypeIdFilter !== null && assetTypeIdFilter !== distribution.assetTypeId) {
					delete filteredObj[id];
				}

				let typeFilter = this.filterBy.type;
				if (!distribution.type || distribution.type.length === 0) {
					let companyObj = this.allCompaniesObj[distribution.companyId];
					let connectedCompanyObj = this.allConnectedCompaniesObj[distribution.connectedCompanyId];
					let assetTypeObj = this.allAssetTypesObj[distribution.assetTypeId];
					distribution.type = AssetPoolDistributionUtil.getDistributionType(distribution, companyObj, connectedCompanyObj, assetTypeObj.originId);
				}
				if (typeFilter !== null && typeFilter !== distribution.type) {
					delete filteredObj[id];
				}
			});

			await this.processAssetPoolDistribution(filteredObj);
		},
		async processAssetPoolDistribution(assetPoolDistributions) {
			// show loading indicator
			this.isLoading = true;

			this.items = Object.values(assetPoolDistributions);
			this.items = _.sortBy(this.items, ['company', 'connectedCompany', 'connectedStorageLocation', 'assetType']);
			this.items = await this.addFieldsForDisplay(this.items);

			// update cache
			this.$store.dispatch('updateAllAssetPoolDistributions', assetPoolDistributions);

			// refresh table
			if (this.$refs.distributionsTable) {
				this.$refs.distributionsTable.refresh();
			}

			// hide loading indicator
			this.isLoading = false;
		},
		async addFieldsForDisplay(items) {
			let dispatchesObj = await this.getInTransitDispatches();
			items.forEach((item) => {
				item['Date Created'] = this.getFormattedDateWithTime(item.dateCreated);
				item['Date Updated'] = this.getFormattedDateWithTime(item.dateUpdated);
				item['connection'] = item.connectedCompany + ' - ' + item.connectedStorageLocation;

                item['type'] = item.type;
				if (!item.type) {
					let companyObj = this.allCompaniesObj[item.companyId];
					let connectedCompanyObj = this.allConnectedCompaniesObj[item.connectedCompanyId];
					let assetTypeObj = this.allAssetTypesObj[item.assetTypeId];
					item['type'] = AssetPoolDistributionUtil.getDistributionType(item, companyObj, connectedCompanyObj, assetTypeObj.originId);
				}
				
				item['assetLimit'] = item.assetLimit ? item.assetLimit : 0;
				item['totalInTransit'] = this.getTotalInTransit(dispatchesObj, item);
				item['totalEstimate'] = this.getTotalEstimate(item);
			});
			return items;
		},
		async getInTransitDispatches(){
			let dispatchesObj = {};
			let companyIds = Object.keys(this.allCompaniesObj);

            // 10 is max equality "OR" operation in "IN"
            let companyIdsBy10 = _.chunk(companyIds, 10);

            for (const groupOf10 of companyIdsBy10) {
                let filter =  {
					sourceCompany: {
						ids: groupOf10
					},
					status: 'In-Transit'
				};

                let currDispatchesObj = await dispatchDAO.getDispatches(filter);
                dispatchesObj = { ...dispatchesObj, ...currDispatchesObj };
            }

			return dispatchesObj;
		},
		getTotalInTransit(dispatchesObj, item) {
			let totalInTransit = 0;

			// filter dispatches for item distribution
			let assetTypeId = item.assetTypeId;
			let filteredDispatches = _.filter(dispatchesObj, o => {
				return o.source.companyId === item.connectedCompanyId 
					&& o.source.storageLocationId === item.connectedStorageLocationId
					&& _.map(o.assets, 'assetTypeId').includes(assetTypeId);
			});

			// retrieve the inTransit Count
			_.forEach(filteredDispatches, dispatch => {
				let dispatchAsset = _.find(dispatch.assets, o => {
					return o.assetTypeId === assetTypeId
				});
				totalInTransit += dispatchAsset.expectedQuantity;
			});

			return totalInTransit;
		},
		getTotalEstimate(item) {
			let totalEstimate = parseInt(item.total) - parseInt(item.totalInTransit);
			return totalEstimate;
		},

		resetPagination() {
			this.filterBy.direction = 'asc';
			this.pagination.startAt = [];
			this.pagination.endAt = [];
		},
		onPageChanged(currentPage) {
			this.pagination.currentPage = currentPage;
		},

		async onFilterRequest() {
			if (!_.isEqual(this.filterBy, this.prevFilter)) {
				
				// reset startAt during fresh query
				this.pagination.startAt = '';

				await this.retrieveData(1, this.pagination.perPage, this.pagination.startAt, {
					...this.filterBy,
				});
				this.prevFilter = { ...this.filterBy };

			}
		},
		async resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = { ...this.defaultFilterBy };
				this.prevFilter = { ...this.filterBy };

				// reset validation
				this.$validator.reset();
				this.errors.clear();

				await this.retrieveData(1, this.pagination.perPage, this.pagination.startAt, { ...this.filterBy });
			}
		},
		async retrieveData(page, perPage, startAt, filterBy) {
			try {
				// show loading indicator
				this.isLoading = true;

				let filters = {
					companyId: !filterBy.company.id ? '' : filterBy.company.id,
					connectedCompanyId: !filterBy.connectedCompany.id ? '' : filterBy.connectedCompany.id,
					connectedStorageLocationId: !filterBy.connectedStorageLocation.id ? '' : filterBy.connectedStorageLocation.id,
					assetTypeId: !filterBy.assetType.id ? '' : filterBy.assetType.id,
					type: !filterBy.type ? '' : filterBy.type,
					view: this.isSuperAdmin ? config.view.ADMIN : config.view.COMPANY,
					currCompanyId: this.loggedUser.companyId,
                    includePrimary: true,
					includeOthers: false,
					hasAssetPoolingPermission: this.loggedUserCompany.permissions.pooling ? 'true' : 'false',
					companyIds: _.map(this.allCompaniesObj, 'id')
				};

				const { data } = await assetPoolDistributionApi.paginateAssetPoolDistributions(
					page,
					perPage,
					startAt,
					filterBy.direction,
					filters,
					this.loggedUser.id
				);

				if (data.isSuccess) {
					this.allAssetPoolDistributionsObj = data.assetPoolDistributions;
					await this.filterTableContent(this.allAssetPoolDistributionsObj);

					let newStartAt = [];
					let newEndAt = [];
					if (!_.isEmpty(this.items)) {
						newStartAt.push(this.items[0].company);
						newStartAt.push(this.items[0].connectedCompany);
						newStartAt.push(this.items[0].connectedStorageLocation);
						newStartAt.push(this.items[0].assetType);

						let endIndex = this.items.length - 1;
						newEndAt.push(this.items[endIndex].company);
						newEndAt.push(this.items[endIndex].connectedCompany);
						newEndAt.push(this.items[endIndex].connectedStorageLocation);
						newEndAt.push(this.items[endIndex].assetType);
					}

					let total = 0;
					if (this.items.length < data.perPage) {
						total = data.perPage * (data.currentPage - 1) + this.items.length;
					} else {
						total = data.perPage * data.currentPage + 1;
					}

					this.pagination = {
						perPage: data.perPage,
						currentPage: data.currentPage,
						total: total,
						startAt: newStartAt,
						endAt: newEndAt
					};
				} else {
					this.$toaster.error('Error loading data. Please reload the page again.');
				}

			} catch (error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},

		updateSelAssetPoolDistribution(item) {
			this.selDistribution = item;
			this.$store.commit('SET_CURR_ASSET_POOL_DISTRIBUTION', item);
		},
		updateAssetLimit(item) {
			this.selDistribution = item;
			this.$store.commit('SET_CURR_ASSET_POOL_DISTRIBUTION', item);
			EventBus.$emit('onUpdateAssetLimit', item);
		},
		isAllowedToCreateDistribution(){
			return this.isSuperAdmin || !this.isViewer && !this.isScanner && !this.isRetailer;
		},
		isAllowedToAdjustTotal(distribution) {
			return (this.isSuperAdmin || (!this.isViewer && !this.isScanner && !this.isRetailer && UserUtil.hasCompanyAccess(this.allCompaniesObj, distribution.companyId)))
				&& distribution.type === 'On-Hand';
		},
		isAllowedToUpdateLimit() {
			return this.isSuperAdmin || !this.isViewer;
		},	

		// UTILS
		isLimitExceeded(item) {
			return item.assetLimit !== 0 && item.total > item.assetLimit;
		},
		getLimitToolTip(item) {
			let exceededLimit = item.total - item.assetLimit;
			return 'This exceeds the asset limit by ' + exceededLimit;
		},
        getFormattedNumber(num) {
            return num && num > 0 ? num.toLocaleString() : 0;
        },
		getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
	},
	beforeDestroy() {
		EventBus.$off('onCloseSaveAssetPoolDistribution');
	},
};
</script>

<style scoped>
.in-transit {
	color: green;
	font-weight: bold;
}

.exceed-limit {
	color: red;
	font-weight: bold;
}

.within-limit {
	color: green;
	font-weight: bold;
}

.field-without-label {
	margin-top: 30px;
}
</style>