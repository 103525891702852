<template>
    <div class="animated fadeIn">
        <loading :active.sync="isLoading" loader="spinner" color="#20A8D8" :is-full-page="false" />
        <span class="details-view-title">OTHER DISTRIBUTIONS</span>
        <div class="details-view-subtitle">Distribution from other companies that has physical count of your owned asset types</div>
        
        <!-- Filter  -->
        <b-row class="mt-4">
            <b-col sm="12" md="2" lg="3">
                <b-button v-b-popover.hover.right="'Toggle to show/hide filter options'" v-b-toggle.collapse-1
                    class="filter">
                    FILTER OPTIONS
                </b-button>
            </b-col>
            <b-col sm="12">
                <!-- Collapsible Filter Options -->
                <b-collapse id="collapse-1" class="mt-2">
                    <b-card>
                        <b-row no-gutters>
                            <b-col lg="4" md="6" sm="12" class="mr-4">
                                <b-form-group label="Asset Type">
                                    <v-select class="style-chooser" label="text" placeholder=" - Please select - "
                                        :options="filterByOptions.assetTypeItems"
                                        :reduce="(assetType) => assetType.value" v-model="filterBy.assetType">
                                        <template v-slot:no-options="{ search, searching }">
                                            <template v-if="searching">
                                                No results found for
                                                <em>
                                                    <strong>{{ search }}</strong>
                                                </em>
                                            </template>
                                            <em :style="{ opacity: 0.5 }" v-else>
                                                Start typing to search for a asset type
                                            </em>
                                        </template>
                                    </v-select>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row no-gutters>
                            <b-col sm="12">
                                <b-button class="mr-1" variant="success" @click="onFilterRequest">
                                    Search
                                </b-button>
                                <b-button class="mr-1" variant="primary" @click="resetFilters">
                                    Reset
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-card>
                </b-collapse>
            </b-col>
        </b-row>

        <!-- Select Actions and Items Per Page Options -->
        <b-row>
            <b-col sm="6" md="3" class="mt-4 mb-2">
                <b-dropdown id="distribution-select-actions" text=" Select Actions " variant="dark" slot="append">
                    <b-dropdown-item>
                        <json-excel type="xls"
                            :name="fileName + '.xls'"
                            :fetch="fetchData"
                            :fields="exportFields"
                            :before-generate="startDownload"
                            :before-finish="finishDownload">
                            Export Other Distributions in Excel
                        </json-excel>
                    </b-dropdown-item>
                    <b-dropdown-item>
                        <json-excel type="csv"
                            :name="fileName + '.csv'"
                            :fetch="fetchData"
                            :fields="exportFields"
                            :before-generate="startDownload"
                            :before-finish="finishDownload">
                            Export Other Distributions to CSV
                        </json-excel>
                    </b-dropdown-item>
                </b-dropdown>
            </b-col>
            <b-col sm="6" md="4" offset-md="5" class="mt-4 mb-2 text-md-right">
                <b-input-group prepend="Show" append="/ Page">
                    <b-form-select :options="pageOptions" v-model="pagination.perPage" />
                </b-input-group>
            </b-col>
        </b-row>

        <b-table ref="otherDistributionsTable" show-empty striped hover :items="items" :fields="fields" responsive>
            <template v-slot:cell(company)="row">
                <span class="company-display">
                    {{ row.item.company }}
                </span>
            </template>
            <template v-slot:cell(connection)="row">
                <span class="company-display">
                    {{ row.item.connectedCompany }}
                </span><br/>
                <span class="location-display">
                    ({{ row.item.connectedStorageLocation }})
                </span>
            </template>
            <template v-slot:cell(type)="row">
                <span v-if="row.item.type === 'On-Hand'">
                    <b-badge variant="primary">{{ row.item.type }}</b-badge>
                </span>
                <span v-else-if="row.item.type === 'On-Hire'">
                    <b-badge variant="warning">{{ row.item.type }}</b-badge>
                </span>
                <span v-else>
                    <b-badge variant="danger">{{ row.item.type }}</b-badge>
                </span>
            </template>
            <template v-slot:cell(total)="row">
                <div class="numFont">
                    <strong>{{ getFormattedNumber(row.item.total) }}</strong>
                </div>
            </template>

            <template v-slot:cell(actions)="row">
                <span class="text-nowrap">
                    <b-button size="sm" v-b-tooltip.hover.top="'Show/Hide Other Details'" variant="dark"
                        @click.stop="row.toggleDetails" class="mr-1">
                        <i class="fa fa-eye-slash" v-if="row.detailsShowing"></i>
                        <i class="fa fa-eye" v-else></i>
                    </b-button>
                </span>
            </template>

            <template slot="row-details" slot-scope="row">
                <AssetPoolDistributionDetailsView :row="row" />
            </template>
        </b-table>

        <b-row class="my-1">
            <b-col md="8" sm="12" >
            </b-col>
            <b-col md="4" sm="12">
                <b-pagination align="right" @change="onPageChanged" :total-rows="pagination.total"
                    :per-page="pagination.perPage" v-model="pagination.currentPage" class="my-0" limit="1" prev-text="Prev"
                    next-text="Next" hide-goto-end-buttons />
            </b-col>
        </b-row>
    </div>
</template>

<script>
// Components
import AssetPoolDistributionDetailsView from '@/views/transactions/assetPoolDistribution/AssetPoolDistributionDetailsView';

// Utils
import { AssetPoolDistributionUtil } from '@/utils/assetPoolDistributionUtil';
import { DateUtil } from '@/utils/dateutil';
import { DropDownItemsUtil } from '@/utils/dropDownItemsUtil';

// API & DAO
import assetPoolDistributionApi from '@/api/assetPoolDistributionApi';

// Others
import config from '@/config/env-constants';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import JsonExcel from 'vue-json-excel';
import _ from 'lodash';


export default {
	name: 'other-distributions',
	components: {
		AssetPoolDistributionDetailsView,
		Loading,
		JsonExcel,
	},
    data() {
		return {
			items: [],
			fields: [
				{
					key: 'company',
					sortable: false,
				},
				{
					key: 'connection',
					sortable: false,
				},
				{
					key: 'assetType',
					sortable: false,
				},
				{
					key: 'type',
					sortable: true,
					class: 'text-right',
				},
				{
					key: 'total',
					label: 'Total',
					sortable: true,
					class: 'text-right',
				},
				{
					key: 'actions',
					class: 'text-center'
				}
			],
			pageOptions: [5, 10, 15, 25, 50, 100],
			pagination: {
				perPage: 5,
				currentPage: 1,
				total: 0,
				startAt: [],
				endAt: []
			},

			defaultFilterBy: {
				assetType: { ...config.assetTypeDefaultValue },
				type: 'On-Hand',
			},
			filterBy: {
				assetType: { ...config.assetTypeDefaultValue },
				type: 'On-Hand',
			},
			prevFilter: {},
			filterByOptions: {
				assetTypeItems: []
			},

			allCompaniesObj: {},
			allConnectedCompaniesObj: {},
			allConnectionsObj: {},
			allStorageLocationsObj: {},
			allAssetTypesObj: {},

			allAssetPoolDistributionsObj: {},

			isSuperAdmin: this.$store.getters.isSuperAdmin,
			loggedUserCompany: this.$store.getters.loggedUserCompany,
			loggedUser: this.$store.getters.loggedUser,
			// Check for loader
			isLoading: false,
		};
	},
    watch: {
		'pagination.perPage'(value) {
			this.resetPagination();
			this.retrieveData(1, value, this.pagination.startAt, { ...this.filterBy });
		},
		'pagination.currentPage'(newVal, oldVal) {
			if (newVal === 1) {
				this.resetPagination();
				this.retrieveData(1, this.pagination.perPage, this.pagination.startAt, { ...this.filterBy });
			} else {
				if (newVal > oldVal) {
					this.filterBy.direction = 'asc';
					this.retrieveData(newVal, this.pagination.perPage, this.pagination.endAt, { ...this.filterBy });
				} else {
					this.filterBy.direction = 'desc';
					this.retrieveData(newVal, this.pagination.perPage, this.pagination.startAt, { ...this.filterBy });
				}
			}
		}
	},
    computed: {
		/**
		 * Derives the field information based from the data table configuration.
		 *
		 * @returns {object} the fields to be included in the export.
		 */
		exportFields() {
			return {
				Company: 'company',
				Connection: 'connection',
				'Asset Type': 'assetType',
				Type: 'type',
				'Total': 'total',
				Notes: 'notes',
				'Date Created': 'Date Created',
				'Date Updated': 'Date Updated',
			};
		},

		fileName() {
			let currTimeStamp = DateUtil.getCurrentTimestamp();
			return 'OthersDistribution-' + DateUtil.getDateInDDMMYYYYHHSSFormat(currTimeStamp);
		},
	},
    mounted() {
		setTimeout(async () => {
			try {
				// show loading indicator
				this.isLoading = true;

				this.allCompaniesObj = { ...this.$store.getters.companies };
				this.allConnectedCompaniesObj = this.isSuperAdmin ? { ...this.allCompaniesObj } : { ...this.$store.getters.connectedCompanies };
				this.allStorageLocationsObj = { ...this.$store.getters.storageLocations, ...this.$store.getters.connectedStorageLocations };
				this.allConnectionsObj = { ...this.$store.getters.connections };
				this.allAssetTypesObj = { ...this.$store.getters.assetTypes };

				this.filterByOptions.companyItems = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);

				if (this.isSuperAdmin) {
					this.filterByOptions.connectedCompanyItems = DropDownItemsUtil.retrieveCompanies(this.allCompaniesObj);
				} else {
					let allCompaniesObj = { ...this.allCompaniesObj, ...this.allConnectedCompaniesObj };
					this.filterByOptions.connectedCompanyItems = DropDownItemsUtil.retrieveCompanies(allCompaniesObj);
				}

				this.filterByOptions.connectedStorageLocationItems = DropDownItemsUtil.retrieveStorageLocations(this.allStorageLocationsObj);

				let publicAssetTypesObj = _.filter(this.allAssetTypesObj, o => { return o.isPublic });
				this.filterByOptions.assetTypeItems = DropDownItemsUtil.retrieveAssetTypes(publicAssetTypesObj, true);

				await this.retrieveData(1, this.pagination.perPage, this.pagination.startAt, { ...this.filterBy });
			} catch (error) {
				this.$toaster.error('Error loading data. Please reload the page again');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		}, config.timeout);
	},
    methods: {
        async fetchData(){
			try {
				let filter = { ...this.filterBy };
				filter.companyId = this.loggedUserCompany.id;
				filter.includePrimary = false;
                filter.includeOthers = true;
				filter.hasAssetPoolingPermission = this.loggedUserCompany.permissions.pooling ? 'true' : 'false';
				filter.companyIds = _.map(this.allCompaniesObj, 'id');

				let view = this.isSuperAdmin ? config.view.ADMIN : config.view.COMPANY;
				let { data } = await assetPoolDistributionApi.getAssetPoolDistributions(filter, view, this.loggedUser.id);

				let exportData = Object.values(data.assetPoolDistributions);
				exportData = this.addFieldsForDisplay(exportData);

				return exportData;
			} catch(error) {
				this.$toaster.error('Error exporting data. Please try again later.');
			}
			return [];
		},
		startDownload(){
			// show loading indicator
			this.isLoading = true;
			this.$toaster.success('Exporting data...');
		},
		finishDownload(){
			// hide loading indicator
			this.isLoading = false;
		},

		filterTableContent(distributionsObj) {
			let filteredObj = { ...distributionsObj };

			_.forEach(distributionsObj, (distribution, id) => {
				let assetTypeIdFilter = this.filterBy.assetType.id;
				if (assetTypeIdFilter !== null && assetTypeIdFilter !== distribution.assetTypeId) {
					delete filteredObj[id];
				}
			});

			this.processAssetPoolDistribution(filteredObj);
		},
        processAssetPoolDistribution(assetPoolDistributions) {
			// show loading indicator
			this.isLoading = true;

			this.items = Object.values(assetPoolDistributions);
			this.items = _.sortBy(this.items, ['company', 'connectedCompany', 'connectedStorageLocation', 'assetType']);
			this.items = this.addFieldsForDisplay(this.items);

			// update cache
			this.$store.dispatch('updateAllAssetPoolDistributions', assetPoolDistributions);

			// refresh table
			if (this.$refs.otherDistributionsTable) {
				this.$refs.otherDistributionsTable.refresh();
			}

			// hide loading indicator
			this.isLoading = false;
		},
        addFieldsForDisplay(items) {
			items.forEach((item) => {
				item['Date Created'] = this.getFormattedDateWithTime(item.dateCreated);
				item['Date Updated'] = this.getFormattedDateWithTime(item.dateUpdated);
				item['connection'] = item.connectedCompany + ' - ' + item.connectedStorageLocation;

				item['type'] = item.type
				if (!item.type) {
					let companyObj = this.allCompaniesObj[item.companyId];
					let connectedCompanyObj = this.allConnectedCompaniesObj[item.connectedCompanyId];
					let assetTypeObj = this.allAssetTypesObj[item.assetTypeId];
					item['type'] = AssetPoolDistributionUtil.getDistributionType(item, companyObj, connectedCompanyObj, assetTypeObj.originId);
				}
			});
			return items;
		},

        resetPagination() {
			this.filterBy.direction = 'asc';
			this.pagination.startAt = [];
			this.pagination.endAt = [];
		},
		onPageChanged(currentPage) {
			this.pagination.currentPage = currentPage;
		},

        async onFilterRequest() {
			if (!_.isEqual(this.filterBy, this.prevFilter)) {
				
				// reset startAt during fresh query
				this.pagination.startAt = '';

				await this.retrieveData(1, this.pagination.perPage, this.pagination.startAt, {
					...this.filterBy,
				});
				this.prevFilter = { ...this.filterBy };

			}
		},
		async resetFilters() {
			if (!_.isEqual(this.filterBy, this.defaultFilterBy)) {
				// reset to default
				this.filterBy = { ...this.defaultFilterBy };
				this.prevFilter = { ...this.filterBy };

				// reset validation
				this.$validator.reset();
				this.errors.clear();

				await this.retrieveData(1, this.pagination.perPage, this.pagination.startAt, { ...this.filterBy });
			}
		},
        async retrieveData(page, perPage, startAt, filterBy) {
			try {
				// show loading indicator
				this.isLoading = true;

				let filters = {
					companyId: this.loggedUserCompany.id,
					assetTypeId: !filterBy.assetType.id ? '' : filterBy.assetType.id,
					type: !filterBy.type ? '' : filterBy.type,
					view: this.isSuperAdmin ? config.view.ADMIN : config.view.COMPANY,
					includePrimary: false,
					includeOthers: true,
					hasAssetPoolingPermission: this.loggedUserCompany.permissions.pooling ? 'true' : 'false',
					companyIds: _.map(this.allCompaniesObj, 'id')
				};

				const { data } = await assetPoolDistributionApi.paginateAssetPoolDistributions(
					page,
					perPage,
					startAt,
					filterBy.direction,
					filters,
					this.loggedUser.id
				);

				if (data.isSuccess) {
					this.allAssetPoolDistributionsObj = data.assetPoolDistributions;
					await this.filterTableContent(this.allAssetPoolDistributionsObj);

					let newStartAt = [];
					let newEndAt = [];
					if (!_.isEmpty(this.items)) {
						newStartAt.push(this.items[0].company);
						newStartAt.push(this.items[0].connectedCompany);
						newStartAt.push(this.items[0].connectedStorageLocation);
						newStartAt.push(this.items[0].assetType);

						let endIndex = this.items.length - 1;
						newEndAt.push(this.items[endIndex].company);
						newEndAt.push(this.items[endIndex].connectedCompany);
						newEndAt.push(this.items[endIndex].connectedStorageLocation);
						newEndAt.push(this.items[endIndex].assetType);
					}

					let total = 0;
					if (this.items.length < data.perPage) {
						total = data.perPage * (data.currentPage - 1) + this.items.length;
					} else {
						total = data.perPage * data.currentPage + 1;
					}

					this.pagination = {
						perPage: data.perPage,
						currentPage: data.currentPage,
						total: total,
						startAt: newStartAt,
						endAt: newEndAt
					};
				} else {
					this.$toaster.error('Error loading data. Please reload the page again.');
				}

			} catch (error) {
				this.$toaster.error('Error loading data. Please reload the page again.');
			} finally {
				// hide loading indicator
				this.isLoading = false;
			}
		},

        // UTILS
		getFormattedNumber(num) {
            return num && num > 0 ? num.toLocaleString() : 0;
        },
        getFormattedDateWithTime(date) {
			return DateUtil.getFormattedDateWithTime(date);
		},
    }
}
</script>

<style scoped>
.field-without-label {
	margin-top: 30px;
}
</style>